<style lang="less" scoped>

</style>
<style lang="less">
  .organization-content {
    .add-dialog {
      .el-form-item {
        &:last-child {
          margin-bottom: 0;
          .el-form-item__content {
            margin-left: 500px !important;
          }
        }
      }
    }
  }
</style>
<template>
	<div class="normal-content organization-content">
		<div class="left">
			<jw-tree ref="tree" v-bind="treeData" @node-click="treeClick" @node-remove="remove" @node-add="nodeAdd" @node-edit="nodeEdit" />
		</div>
		<div class="right">
			<jw-table ref="table" v-bind="table" @search="search" />
		</div>
		<el-dialog :title="isAdd?'添加岗位':'编辑岗位'" :visible.sync="addVisible" custom-class="add-dialog default-dialog-form-style" @close="closeAddDialog">
			<jw-form v-if="addVisible" ref="addForm" v-bind="addForm" class="add-form" />
		</el-dialog>
	</div>
</template>
<script lang="jsx">
import Server from './apis'
export default {
  name: 'CrmOrganizationManage',
  components: {},
  props: {},
  data () {
    const that = this
    return {
      state: false,
      root: {
        edit: false,
        delete: false
      },
      currentDepId: '', // 编辑岗位 当前组织Id
      currentStationId: '', // 编辑岗位 当前岗位Id
      isAdd: true,
      slotInputValue: '',
      addVisible: false,
      filters: {
        pageNum: 1,
        pageSize: 9999,
        sort: decodeURIComponent('{"gmtCreate":"asc"}')
      },
      staFilters: {
        pageNum: 1,
        pageSize: 10,
        sort: decodeURIComponent('{"gmtCreate":"desc"}')
      },
      addForm: {
        bind: {
          props: {
            labelWidth: '192px',
            rules: {
              stationName: [{
                required: true,
                message: '不能为空，请输入'
              },
              {
                max: 10,
                message: '字数已超过最大限制10'
              },
              {
                validator: function (rule, value, callback) {
                  if (!value.trim()) {
                    callback(new Error('不能为空！'))
                    return
                  }
                  that.$apis.getStationVerifyName({
                    departmentId: that.isAdd ? that.staFilters.departmentId : that.currentDepId,
                    stationName: value,
                    stationId: that.isAdd ? '' : that.currentStationId
                  }, (data) => {
                    if (data === 0) {
                      callback()
                    } else {
                      callback(new Error('已存在！'))
                    }
                  })
                },
                trigger: 'blur'
              }
              ],
              stationType: {
                required: true,
                message: '不能为空，请输入'
              },
              pStationId: {
                required: true,
                message: '不能为空'
              }
            }
          }
        },
        data: [{
          tag: 'el-input',
          label: '岗位名称',
          prop: 'stationName',
          bind: {
            attrs: {
              placeholder: '请输入岗位名称'
            },
            on: {
              input (e) {
                that.$refs.addForm.$setValueField('stationName', e)
              }
            },
            nativeOn: {
              compositionstart (e) {
                that.state = true
              },
              compositionend (e) {
                that.state = false
                if (!self.state) {
                  e.target.value = e.target.value.replace(/[^\u4e00-\u9fa5_a-zA-Z0-9]+/gm, '')
                  that.$refs.addForm.$setValueField('stationName', e.target.value)
                }
              },
              input (e) {
                if (!that.state) {
                  e.target.value = e.target.value.replace(/[^\u4e00-\u9fa5_a-zA-Z0-9]+/gm, '')
                  that.$refs.addForm.$setValueField('stationName', e.target.value)
                }
              }
            }
          }
        }, {
          tag: 'el-radio-group',
          label: '岗位类型',
          write: true,
          prop: 'stationType',
          options: [{
            value: '3',
            label: '普通岗位',
            bind: {
              props: {
                disabled: false
              }
            }
          },
          {
            value: '1',
            label: '负责人岗位',
            bind: {
              props: {
                disabled: false
              }
            }
          }
          ],
          bind: {
            on: {
              change (v) {
                if (v === '1') {
                  that.addForm.hide.pStationId = true
                  that.$refs.addForm.$setValueField('pStationId', 0)
                } else {
                  that.$refs.addForm.$setValueField('pStationId', '')
                  that.getUpStation('1')
                }
              }
            }
          }
        }, {
          tag: 'el-select',
          label: '上级岗位',
          prop: 'pStationId',
          options: [],
          bind: {
            props: {
              'no-data-text': '请先新建上级岗位',
              placeholder: '请选择上级岗位'
            }
          }
        }, {
          tag: 'btns',
          label: '确定', // 不传默认提交
          fuc (value) {
            if (value) {
              that.addForm.data[3].bind.props.loading = true
              that.modifyStation()
            }
          },
          bind: {
            props: {
              loading: false
            }
          },
          btns: [{
            bind: {
              on: {
                click () {
                  that.addVisible = false
                }
              }
            },
            name: '取消'
          }]
        }],
        hide: {
          pStationId: true
        },
        value: {
          stationName: '',
          stationType: '',
          pStationId: ''
        }
      },
      treeData: {
        bind: {
          'highlight-current': true
        },
        reSizeable: true,
        addSort: 'down',
        maxlength (node) {
          return 20
        },
        noParent: 0,
        props: {
          nodeKey: 'id',
          parentKey: 'parentId',
          label: 'name'
        },
        addTitle () {
          return '新增下级组织'
        },
        title: '组织管理',
        options: {
          search: true
        },
        data: []
      },
      table: {
        btns: [{
          label: '添加岗位',
          hide: true,
          bind: {
            props: {
              disabled: true
            }
          },
          click: () => {
            this.addForm.data[3].bind.props.loading = false
            this.getUpStation('1', true)
            this.isAdd = true
            this.addVisible = true
          }
        }],
        page: {
          total: '',
          size: 20
        },
        data: [],
        cols: [{
          label: '岗位名称',
          prop: 'stationName',
          minWidth: 200
        }, {
          label: '岗位类型',
          prop: 'stationType',
          width: 200,
          filterMultiple: false,
          filters: [{
            text: '全部',
            value: ''
          },
          {
            text: '负责人岗位',
            value: 1
          },
          {
            text: '普通岗位',
            value: 3
          }
          ],
          fuc (row, h) {
            return {
              type: 'text',
              value: ['', '负责人岗位', '', '普通岗位'][parseInt(row.stationType)]
            }
          }
        }, {
          label: '操作',
          width: 100,
          fuc: (row, h, index, i) => {
            const btns = [{
              label: '编辑',
              no: false,
              hide: !that.root.edit,
              click: function () {
                that.editStation(row)
              }
            },
            {
              label: '删除',
              no: false,
              hide: !that.root.delete,
              click: function () {
                that.deleteStation(row)
              }
            }
            ]
            return {
              type: 'btns',
              btns: btns
            }
          }
        }]
      }
    }
  },
  watch: {
    isAdd (val) {
      const addFormData = this.addForm.data
      if (val) {
        addFormData[1].tag = 'el-radio-group'
        addFormData[2].tag = 'el-select'
      } else {
        addFormData[1].tag = 'text'
        addFormData[2].tag = 'text'
      }
    }
  },
  beforeCreate () {
    this.$apis = new Server(this.$axios, this.$message.error)
  },
  created () {
    this.setStationRoot()
    // this.getOrgansDepartments()
    this.getOrgans({
      pageNum: 1,
      pageSize: 9999
    })
  },
  mounted () {},
  methods: {
    /** 设置权限
       */
    setStationRoot () {
      const root = this.root
      if (this.permission('FA431DAA-5E7F-451D-9DAE-20C8EB46BBB8')) {
        root.edit = true
      }
      if (this.permission('492BAC49-6058-4436-8135-17AD9B83D8CA')) {
        root.delete = true
      }
      if (this.permission('B2D79DDE-F3DB-4D49-8F34-CC8E83B9BD7C')) {
        this.table.btns[0].hide = false
      }
    },
    /** 删除岗位
       */
    deleteStation (row) {
      this.$apis.checkStation(row.stationId, (data) => {
        if (data === 0) {
          this.$confirm('删除后无法恢复，您确定要删除吗？', '删除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$apis.deleteStation(row.stationId, (_dtta) => {
              this.$message.success('已删除')
              this.getStations()
            })
          })
        } else {
          this.$alert('该岗位下已关联员工，需要先移除该员工才可删除。', '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    /** 关闭弹窗
       */
    closeAddDialog () {
      this.$refs.addForm.$resetFields()
      this.addForm.data[1].options[1].bind.props.disabled = false
      this.addForm.data[2].options = []
      this.addForm.hide.pStationId = true
      this.currentDepId = ''
      this.currentStationId = ''
      this.stationName = ''
    },
    /** 获取组织机构树 数据
       */
    // getOrgansDepartments () {
    //   let addRoot = this.permission('8085C3C6-E903-4069-B4B6-AF913E23034D')
    //   let editRoot = this.permission('5BDBB30C-8E7F-4267-9002-5D47BE3925A7')
    //   let deleteRoot = this.permission('F1403F9D-5955-4AB7-9057-0A61E2B669F2')
    //   this.$apis.getOrgansDepartments(this.filters, (data, noParentId) => {
    //     this.treeData.noParent = noParentId
    //     data.forEach((el) => {
    //       el.options = {
    //         add: addRoot,
    //         other: []
    //       }
    //       if (el.isDep) {
    //         if (deleteRoot) {
    //           el.options.other.push({
    //             label: '删除',
    //             props: {
    //               command: 'remove'
    //             }
    //           })
    //         }
    //         if (editRoot) {
    //           el.options.other.push({
    //             label: '编辑',
    //             props: {
    //               command: 'edit'
    //             }
    //           })
    //         }
    //       }
    //     })
    //     this.treeData.data = data
    //     setTimeout(() => {
    //       let el = this.$el.querySelector('.el-tree-node__content .label')
    //       el && el.click()
    //     }, 0)
    //   })
    // },
    getOrgans (opt) {
      const addRoot = this.permission('8085C3C6-E903-4069-B4B6-AF913E23034D')
      this.$apis.getOrgans(opt).then(res => {
        this.treeData.noParent = res.data.data && res.data.data[0] && res.data.data[0].pOrganId
        this.getDepartments((res.data.data || []).map(item => {
          return {
            pOrganId: item.pOrganId,
            sharesType: item.sharesType,
            organName: item.organName,
            partnerId: item.partnerId,
            id: item.partnerId,
            name: item.organName,
            parentId: item.pOrganId,
            options: {
              add: addRoot,
              other: []
            }
          }
        }), {
          pageNum: 1,
          pageSize: 9999
        })
      }).catch(({ response }) => {
        this.treeData.data = []
        Server.$message(this, response)
      })
    },
    getDepartments (tree, opt) {
      console.log(tree)
      const addRoot = this.permission('8085C3C6-E903-4069-B4B6-AF913E23034D')
      const editRoot = this.permission('5BDBB30C-8E7F-4267-9002-5D47BE3925A7')
      const deleteRoot = this.permission('F1403F9D-5955-4AB7-9057-0A61E2B669F2')
      this.$apis.getDepartments(this._auth.userInfo.partnerId, opt).then(res => {
        this.treeData.data = res.data.data.map(item => {
          const other = []
          if (editRoot) {
            other.push({
              label: '编辑',
              props: {
                command: 'edit'
              }
            })
          }
          if (deleteRoot) {
            other.push({
              label: '删除',
              props: {
                command: 'remove'
              }
            })
          }
          return {
            departmentName: item.departmentName,
            level: item.level,
            partnerId: item.partnerId,
            departmentId: item.departmentId,
            parentId: Number(item.level) === 1 ? item.partnerId : ['DEP', item.parentId].join(','),
            id: ['DEP', item.departmentId].join(','),
            name: item.departmentName,
            options: {
              add: addRoot,
              other: other
            }
          }
        }).concat(tree)
        console.log(this.treeData.data, 9999999)
        this.$nextTick(() => {
          const el = this.$el.querySelector('.el-tree-node__content .label')
          el && el.click()
        })
      }).catch(({ response }) => {
        this.treeData.data = []
        Server.$message(this, response)
      })
    },
    /** 新增 编辑岗位
       */
    modifyStation () {
      const formValue = this.$refs.addForm.$getValue()
      const subObj = {
        organId: this.staFilters.organId,
        departmentId: this.staFilters.departmentId,
        pStationId: formValue.pStationId,
        stationName: formValue.stationName,
        stationType: formValue.stationType
      }
      if (this.isAdd) { // 新增岗位
        this.$apis.postStation(subObj, (data) => {
          this.$message.success('已添加')
          this.addForm.data[3].bind.props.loading = false
          this.addVisible = false
          this.getStations()
        })
      } else { // 编辑岗位
        this.$apis.putStation(formValue.stationName, this.currentStationId, (data) => {
          this.$message.success('已编辑')
          this.addForm.data[3].bind.props.loading = false
          this.addVisible = false
          this.getStations()
        })
      }
    },
    /** 编辑岗位
       */
    editStation (row) {
      this.isAdd = false
      this.addVisible = true
      this.currentStationId = row.stationId
      this.currentDepId = row.departmentId
      this.$apis.getStation(row.stationId, (data) => {
        const obj = {
          stationName: data.stationName,
          stationType: ['', '负责人岗位', '', '普通岗位'][data.stationType - 0],
          pStationId: data.pStationName
        }
        if (data.pStationName) {
          this.addForm.hide.pStationId = false
        }
        this.slotInputValue = data.stationName
        this.$refs.addForm.$setValue(obj)
      })
    },
    /** 编辑组织
       */
    putDepartment (depId, depName, parentId) {
      return new Promise((resolve, reject) => {
        this.$apis.putDepartment(depId, depName, parentId, (data) => {
          resolve()
          this.$message.success('已编辑')
        })
      })
    },
    treeClick (data, node, store) {
      const staFilters = this.staFilters
      staFilters.pageSize = 20
      staFilters.pageNum = 1
      staFilters.organId = node.partnerId
      staFilters.departmentId = String(node.id).indexOf('DEP') > -1 ? node.departmentId : ''
      staFilters.stationType = ''
      this.table.data = []
      if (this.table.btns[0]) {
        this.table.btns[0].bind.props.disabled = true
      }
      this.getStations()
    },
    /** 删除组织
       */
    remove (data, cb) {
      this.$apis.checkDepartment(data.departmentId, (_data) => {
        if (_data === 0) {
          this.$confirm('删除后无法恢复，您确定要删除吗？', '删除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$apis.deleteDepartment(data.departmentId, (deleData) => {
              this.$message.success('已删除')
              cb()
              this.table.btns[0].bind.props.disabled = true
            })
          })
        } else {
          this.$alert('该组织下已有数据，需要先移除所有数据后才可删除。', '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    /** 获取上级岗位列表
       */
    getUpStation (stationType, isCheckUp = false) {
      const filters = Object.assign({}, this.staFilters, {
        stationType: stationType,
        pageNum: 1,
        pageSize: 9999
      })
      this.$apis.getStations(filters, (data) => {
        if (isCheckUp) {
          this.addForm.hide.pStationId = true
          if (data.page.totalCount > 0) {
            this.addForm.data[1].options[1].bind.props.disabled = true
          }
          return
        }
        this.addForm.data[2].options = data.data.map((item) => {
          return {
            value: item.stationId,
            label: item.stationName
          }
        })
        this.addForm.hide.pStationId = false
      })
    },
    /** 获取岗位列表
       */
    getStations () {
      const that = this
      this.$nextTick(() => {
        that.$refs.table && that.$refs.table.$startLoading()
      })
      that.$apis.getStations(this.staFilters, (data) => {
        that.table.data = data.data
        that.table.page = {
          total: data.page.totalCount
        }
        if (this.staFilters.departmentId) {
          that.table.btns[0].bind.props.disabled = false
        }
        that.$refs.table && that.$refs.table.$stopLoading()
      }, function () {
        that.$nextTick(() => {
          that.$refs.table && that.$refs.table.$stopLoading()
        })
      })
    },
    search (data, selecttion) {
      this.staFilters.pageNum = data.page
      this.staFilters.pageSize = data.size
      this.staFilters.stationType = selecttion.stationType ? selecttion.stationType[0] + '' : ''
      this.getStations()
    },
    /** clickEdit
       */
    nodeEdit (data, cb) {
      // console.log(data)
      if (data.nochange) {
        return
      }
      this.getDepartmentVerifyName({
        organId: data.data.partnerId,
        departmentId: data.data.departmentId,
        departmentName: data.label,
        parentId: data.data.level === 1 ? 0 : data.data.departmentId
      }).then((_data) => {
        if (_data === 0) {
          this.putDepartment(data.data.departmentId, data.label, data.data.level === 1 ? 0 : data.data.departmentId).then(() => {
            cb()
            this.$message.success('已编辑')
          })
        } else {
          cb(new Error('已存在!'))
        }
      })
    },
    /** 校验组织名称
       */
    getDepartmentVerifyName (obj) {
      if (!obj.departmentName.trim()) {
        return
      }
      return new Promise((resolve, reject) => {
        this.$apis.getDepartmentVerifyName(obj, (data) => {
          resolve(data)
        })
      })
    },
    /** clickadd
       */
    nodeAdd (data, cbk) {
      const parentData = data.node.parent.data
      const checkObj = {
        organId: parentData.partnerId,
        departmentId: '',
        departmentName: data.label,
        parentId: String(parentData.id).indexOf('DEP') > -1 ? parentData.departmentId : 0
      }
      const editRoot = this.permission('5BDBB30C-8E7F-4267-9002-5D47BE3925A7')
      const deleteRoot = this.permission('F1403F9D-5955-4AB7-9057-0A61E2B669F2')
      this.getDepartmentVerifyName(checkObj).then((_data) => {
        if (_data === 0) {
          const obj = {
            organId: parentData.partnerId,
            parentId: String(parentData.id).indexOf('DEP') > -1 ? parentData.departmentId : 0,
            departmentName: data.label
          }
          this.$apis.postDepartment(obj, (item) => {
            const other = []
            if (editRoot) {
              other.push({
                label: '编辑',
                props: {
                  command: 'edit'
                }
              })
            }
            if (deleteRoot) {
              other.push({
                label: '删除',
                props: {
                  command: 'remove'
                }
              })
            }
            cbk({
              departmentName: item.departmentName,
              level: item.level,
              partnerId: item.partnerId,
              departmentId: item.departmentId,
              parentId: Number(item.level) === 1 ? item.partnerId : ['DEP', item.parentId].join(','),
              id: ['DEP', item.departmentId].join(','),
              name: item.departmentName,
              options: {
                add: true,
                other: other
              }
            })
            this.$message.success('已新增')
            // this.getOrgans({
            //   pageNum: 1,
            //   pageSize: 9999
            // })
            // this.getOrgansDepartments()
          })
        } else {
          cbk(new Error('已存在！'))
        }
      })
    }
  }
}
</script>
