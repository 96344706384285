import Server from '@/apis'
const { apiCrmOS } = Server.path
export default class extends Server {
  /**
   * 组织相关接口
   */

  // /**
  //  *获取机构组织列表
  //  * @param {*} o
  //  * @param {*} cb
  //  * @param {*} err
  //  */
  // getOrgansDepartments (o, cb, err) {
  //   Promise.all([this.$http.get(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/organs`, { params: o }), this.$http.get(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/departments`, { params: o })]).then((values) => {
  //     const oragnData = values[0].data.data
  //     const depData = values[1].data.data
  //     const noParentId = oragnData[0] && oragnData[0].pOrganId
  //     oragnData.forEach((el) => {
  //       el.options = {
  //         add: true
  //       }
  //       el.parentKey = el.pOrganId
  //       el.nodeKey = el.partnerId
  //       el.label = el.organName
  //       el.isOrgan = true
  //     })
  //     depData.forEach((el) => {
  //       el.isDep = true
  //       // if (el.parentId === noParentId) {
  //       //   el.parentKey = el.partnerId
  //       // } else {
  //       //   el.parentKey = 'node' + el.parentId
  //       // }
  //       el.parentKey = Number(el.level) === 1 ? el.partnerId : ['DEP', el.parentId].join(',')
  //       el.nodeKey = ['DEP', el.departmentId].join(',')
  //       el.label = el.departmentName
  //       el.isOrgan = false
  //       el.nodeKey = 'node' + el.departmentId
  //     })
  //     const comData = depData.concat(oragnData)
  //     cb(comData, noParentId)
  //   }, this.error(err))
  // }
  /**
   *获取组织列表
   * @param {*} opt
   */
  getDepartments (partnerId, opt) {
    return this.$http.get(Server.path.apiCrmOS + `partner/${partnerId}/departments`, { params: Server.clearNull(opt) })
  }

  /**
   *获取机构列表
   * @param {*} opt
   */
  getOrgans (opt) {
    return this.$http.get(Server.path.apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/organs`, { params: Server.clearNull(opt) })
  }

  /**
   *新增组织
   * @param {*} o
   * @param {*} cb
   * @param {*} err
   */
  postDepartment (o, cb, err) {
    this.$http.post(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/department`, o).then((res) => {
      cb(res.data)
    }, this.error(err))
  }
  /**
   *修改组织
   * @param {*} departmentId
   * @param {*} departmentName
   * @param {*} cb
   * @param {*} err
   */

  putDepartment (departmentId, departmentName, parentId, cb, err) {
    this.$http.put(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/department/${departmentId}`, { departmentName, parentId }).then((res) => {
      cb(res.data)
    }, this.error(err))
  }

  /**
 *删除组织
 * @param {*} departmentId
 * @param {*} cb
 * @param {*} err
 */
  deleteDepartment (departmentId, cb, err) {
    this.$http.delete(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/department/${departmentId}`).then((res) => {
      cb(res.data)
    }, this.error(err))
  }

  /**
   *校验机构下组织名称是否重复
   * @param {*} o
   * @param {*} cb
   * @param {*} err
   */
  getDepartmentVerifyName (o, cb, err) {
    this.$http.get(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/department/verify-name`, { params: o }).then((res) => {
      cb(res.data)
    }, this.error(err))
  }

  /**
   *校验组织下是否有关联数据
   * @param {*} o
   * @param {*} departmentId
   * @param {*} cb
   * @param {*} err
   */
  checkDepartment (departmentId, cb, err) {
    this.$http.get(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/department/${departmentId}/check-lower`).then((res) => {
      cb(res.data)
    }, this.error(err))
  }

  /**
   *岗位相关接口
   */

  /**
    *获取岗位列表
    */
  getStations (o, cb, err) {
    this.$http.get(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/stations`, { params: Server.clearNull(o) }).then((res) => {
      cb(res.data)
    }, this.error(err))
  }

  /**
   * 新增岗位
   * @param {*} o
   * @param {*} cb
   * @param {*} err
   */
  postStation (o, cb, err) {
    this.$http.post(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/station`, Server.clearTrim(o)).then((res) => {
      cb(res.data)
    }, this.error(err))
  }

  /**
   *删除岗位
   * @param {*} stationId
   * @param {*} cb
   * @param {*} err
   */
  deleteStation (stationId, cb, err) {
    this.$http.delete(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/station/${stationId}`).then((res) => {
      cb(res.data)
    }, this.error(err))
  }
  /**
   *获取岗位信息
   * @param {*} stationId
   * @param {*} cb
   * @param {*} err
   */

  getStation (stationId, cb, err) {
    this.$http.get(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/station/${stationId}`).then((res) => {
      cb(res.data)
    }, this.error(err))
  }

  /**
   *修改岗位
   * @param {*} stationName
   * @param {*} stationId
   * @param {*} cb
   * @param {*} err
   */
  putStation (stationName, stationId, cb, err) {
    this.$http.put(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/station/${stationId}`, { stationName: stationName }).then((res) => {
      cb(res.data)
    }, this.error(err))
  }

  /**
   *校验岗位名称是否重复
   * @param {*} o
   * @param {*} cb
   * @param {*} err
   */
  getStationVerifyName (o, cb, err) {
    this.$http.get(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/station/verify-name`, { params: Server.clearTrim(o) }).then((res) => {
      cb(res.data)
    }, this.error(err))
  }

  /**
   *校验岗位是否有关联数据
   * @param {*} stationId
   * @param {*} cb
   * @param {*} err
   */
  checkStation (stationId, cb, err) {
    this.$http.get(apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/station/${stationId}/check-lower`).then((res) => {
      cb(res.data)
    }, this.error(err))
  }
}
